import {
  BulkDeleteButton,
  Create,
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  FunctionField,
  Labeled,
  List,
  SaveButton,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  WrapperField,
} from "react-admin";
import LocalisedDateField from "../components/localisedDateField";

const ProductFilters = [
  <TextInput label="Name" source="name__icontains" alwaysOn />,
  <TextInput label="Description" source="description__icontains" alwaysOn />,
];

export const ProductTagsList = () => (
  <List filter={{ active: true }} filters={ProductFilters}>
    <Datagrid bulkActionButtons={<BulkDeleteButton mutationMode="pessimistic" confirmTitle="Delete Product Tags" />}>
      <TextField source="name" />
      <TextField source="description" />
      <WrapperField label="Actions">{<EditButton />}</WrapperField>
    </Datagrid>
  </List>
);

const ProductTagsCreateOrUpdate = () => (
  <>
    <Labeled label="Description">
      <TextInput source="description" fullWidth />
    </Labeled>
    <FunctionField
      render={(record: any) => {
        if (record.createdAt) {
          return (
            <Labeled label="Created ">
              <LocalisedDateField record={record} field="createdAt" />
            </Labeled>
          );
        }
      }}
    />
    <FunctionField
      render={(record: any) => {
        if (record.updatedAt) {
          return (
            <Labeled label="Updated ">
              <LocalisedDateField record={record} field="updatedAt" />
            </Labeled>
          );
        }
      }}
    />
  </>
);
const DeleteWithConfirmationToolbar = (
  <Toolbar sx={{ justifyContent: "space-between" }}>
    <SaveButton label="Update" />
    <DeleteButton label="Delete" sx={{ textAlign: "right" }} mutationMode="pessimistic" confirmTitle="Delete Tag" />
  </Toolbar>
);
export const ProductTagsEdit = () => (
  <Edit>
    <SimpleForm toolbar={DeleteWithConfirmationToolbar}>
      <Labeled label="Tag name">
        <TextField source="name" />
      </Labeled>
      <ProductTagsCreateOrUpdate />
    </SimpleForm>
  </Edit>
);

export const ProductTagsCreate = () => (
  <Create>
    <SimpleForm>
      <Labeled label="Tag name">
        <TextInput source="name" />
      </Labeled>
      <ProductTagsCreateOrUpdate />
    </SimpleForm>
  </Create>
);
