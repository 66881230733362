import { Alert } from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import {
  AutocompleteArrayInput,
  ChipField,
  Create,
  CreateButton,
  DatagridConfigurable,
  Edit,
  EditButton,
  ExportButton,
  FilterButton,
  ImageField,
  List,
  NumberInput,
  Pagination,
  ReferenceArrayField,
  ReferenceArrayInput,
  ReferenceField,
  SelectColumnsButton,
  SelectInput,
  Show,
  ShowButton,
  SingleFieldList,
  TabbedForm,
  TabbedShowLayout,
  TextField,
  TextInput,
  TopToolbar,
  TranslatableFields,
  TranslatableInputs,
  WrapperField,
  required,
  useRecordContext,
} from "react-admin";
import { Control, useForm, useWatch } from "react-hook-form";
import ImageArrayInputWithPreview, { ImageArrayFieldWithPreview } from "../components/ImageArrayInputWithPreview";
import { PricesTabHeader } from "../components/PricesTabHeader";
import { SummaryTabHeader } from "../components/SummaryTabHeader";
import { TranslationsTabHeader } from "../components/TranslationsTabHeader";
import dataProvider from "../providers/grapheneDataProvider2";
import { Locale } from "./locales";

const filterToQueryTags = (searchText: string) => ({ name__icontains: `${searchText}`, active: true });
const MyPagination = () => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 500, 1000]} />;
const productFilters = [
  <TextInput label="ID" source="pk" alwaysOn />,
  <TextInput label="Name" source="name__icontains" alwaysOn />,
  <TextInput label="SKU" source="prodSku__icontains" alwaysOn />,
  <ReferenceArrayInput source="tags__all" reference="productTags" alwaysOn perPage={100}>
    <AutocompleteArrayInput label="Tags in" filterToQuery={filterToQueryTags} />
  </ReferenceArrayInput>,
  <ReferenceArrayInput source="tags__nin" reference="productTags" alwaysOn perPage={100}>
    <AutocompleteArrayInput label="Tags not in" filterToQuery={filterToQueryTags} />
  </ReferenceArrayInput>,
];

const MyActions = ({ hasCreate = false }) => {
  return (
    <TopToolbar>
      {hasCreate ? <CreateButton /> : <></>}
      <FilterButton />
      <SelectColumnsButton />
      <ExportButton />
    </TopToolbar>
  );
};

const PriceField = () => {
  const record = useRecordContext();
  return (
    <span>
      {record.unitAmount} {record.currency.toUpperCase()} ({record.priceId}) {record.type}{" "}
      {record.type === "recurring" && record.recurringIntervalCount > 1 && record.recurringIntervalCount}{" "}
      {record.type === "recurring" && record.recurringInterval && record.recurringInterval}{" "}
      {record.type === "recurring" && "trial days "} {record.type === "recurring" && record.recurringTrialPeriodDays}{" "}
    </span>
  );
};

export const NewProductList = (props: any) => {
  return (
    <List filters={productFilters} actions={<MyActions {...props} />} pagination={<MyPagination />}>
      <DatagridConfigurable
        omit={[
          "descriptionEn",
          "descriptionDe",
          "prodSku",
          "prodBarcode",
          "prodOrderTitleEn",
          "prodOrderTitleDe",
          "prodMarketingTitleEn",
          "prodMarketingTitleDe",
          "prodImageTooltipTextEn",
          "prodImageTooltipTextDe",
        ]}
      >
        <TextField source="productId" />
        <ImageField sx={{ "& img": { maxWidth: 100, maxHeight: 50, objectFit: "contain" } }} source="image" />
        <TextField source="name" />
        <ReferenceArrayField label="Tags" reference="productTags" source="tags" perPage={100}>
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <WrapperField label="Actions">{props.hasEdit ? <EditButton /> : <ShowButton />}</WrapperField>
        <TextField source="prodSku" />
        <TextField source="prodBarcode" />
      </DatagridConfigurable>
    </List>
  );
};

interface PricesFormProps {
  control: Control<any>;
}

const PricesShow: React.FC = () => {
  const record = useRecordContext();
  const localeIds = record.locales;
  const [priceLocaleKeys, setPriceLocaleKeys] = useState<string[] | null>(null);

  useEffect(() => {
    if (localeIds) {
      let p_keys: string[] = [];
      dataProvider.getMany("locales", { id__in: localeIds }).then(({ data }) => {
        data.forEach((locale: Locale) => {
          locale?.supportedCurrencies.forEach((curr) => p_keys.push(`${curr}-${locale.country}`));
        });
        setPriceLocaleKeys([...new Set(p_keys)]);
      });
    }
  }, [localeIds]);

  if (!localeIds || localeIds.length === 0) {
    return (
      <Alert severity="warning" sx={{ width: "100%" }}>
        Please select locales
      </Alert>
    );
  }

  if (!priceLocaleKeys || priceLocaleKeys.length === 0) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <TranslatableFields
        locales={priceLocaleKeys}
        defaultLocale={priceLocaleKeys[0]}
        // sx={{ minWidth: "400px" }}
        groupKey="prices"
      >
        <ReferenceField source="prices.crossedPrice" reference="prices">
          <PriceField />
          {/* <TextField label="Crossed Price" optionText={} /> */}
        </ReferenceField>
        <ReferenceField source="prices.currentPrice" reference="prices">
          <PriceField />
          {/* <TextField label="Current Price" optionText={<PriceField />} /> */}
        </ReferenceField>
        <TextField
          source="prices.productSku"
          // helperText={"(Optional) Product Overwrite sku default:  " + record.prodSku}
        />
      </TranslatableFields>
    </>
  );
};

const TranslationsShow: React.FC = () => {
  const record = useRecordContext();
  const localeIds = record.locales;
  const [translationLocaleKeys, setTranslationLocaleKeys] = useState<string[] | null>(null);

  useEffect(() => {
    if (localeIds) {
      let t_keys: string[] = ["en-GB"];
      dataProvider.getMany("locales", { id__in: localeIds }).then(({ data }) => {
        data.forEach((locale: Locale) => {
          locale?.supportedLanguages.forEach((lang) => t_keys.push(`${lang}-${locale.country}`));
        });
        setTranslationLocaleKeys([...new Set(t_keys)]);
      });
    }
  }, [localeIds]);

  if (!localeIds || localeIds.length === 0) {
    return (
      <Alert severity="warning" sx={{ width: "100%" }}>
        Please select locales
      </Alert>
    );
  }

  if (!translationLocaleKeys) {
    return <p>Loading...</p>;
  }
  return (
    <>
      <Alert severity="info" sx={{ width: "100%" }}>
        "en-GB" fields are required
      </Alert>
      <TranslatableFields locales={translationLocaleKeys} defaultLocale={"en-GB"} groupKey="translations">
        <TextField source="translations.prodMarketingTitle" label="Marketing title" />
        <TextField source="translations.prodOrderTitle" label="Order title" />
        <TextField source="translations.description" label="Description" />

        <TextField source="translations.prodImageTitle" label="Image title" />
        <TextField source="translations.prodImageCornerNote" label="Image corner note" />
        <TextField source="translations.prodImageTooltipText" label="Image tooltip text" />
        <ImageArrayFieldWithPreview source="translations.images" />
      </TranslatableFields>
    </>
  );
};

export const NewProductsShow = () => {
  return (
    <Show>
      <TabbedShowLayout syncWithLocation={false}>
        <TabbedShowLayout.Tab label={<SummaryTabHeader />}>
          <TextField source="name" sx={{ minWidth: "400px" }} />
          <ReferenceArrayField label="Tags" reference="productTags" source="tags" perPage={100}>
            <SingleFieldList>
              <ChipField source="name" />
            </SingleFieldList>
          </ReferenceArrayField>
          <TextField source="prodSku" sx={{ minWidth: "400px" }} />
          <TextField source="prodBarcode" sx={{ minWidth: "400px" }} />
          <TextField source="image" sx={{ minWidth: "400px" }} />
          <ImageField sx={{ "& img": { maxWidth: 400, maxHeight: 100, objectFit: "contain" } }} source="image" />
          <ReferenceArrayField source="locales" reference="locales">
            <SingleFieldList>
              <ChipField source="name" />
            </SingleFieldList>
          </ReferenceArrayField>
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label={<TranslationsTabHeader />}>
          <TranslationsShow />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label={<PricesTabHeader />}>
          <PricesShow />
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </Show>
  );
};

const PricesInputForm: React.FC<PricesFormProps> = () => {
  const record = useRecordContext();
  const localeIds = useWatch({ name: "locales" });
  const [priceLocaleKeys, setPriceLocaleKeys] = useState<string[] | null>(null);

  useEffect(() => {
    if (localeIds) {
      let p_keys: string[] = [];
      dataProvider.getMany("locales", { id__in: localeIds }).then(({ data }) => {
        data.forEach((locale: Locale) => {
          locale?.supportedCurrencies.forEach((curr) => p_keys.push(`${curr}-${locale.country}`));
        });
        setPriceLocaleKeys([...new Set(p_keys)]);
      });
    }
  }, [localeIds]);

  if (!localeIds || localeIds.length === 0) {
    return (
      <Alert severity="warning" sx={{ width: "100%" }}>
        Please select locales
      </Alert>
    );
  }

  if (!priceLocaleKeys || priceLocaleKeys.length === 0) {
    return <p>Loading...</p>;
  }

  return (
    <TranslatableInputs
      locales={priceLocaleKeys}
      defaultLocale={priceLocaleKeys[0]}
      StackProps={{
        direction: "row",
        spacing: 2,
        flexWrap: "wrap",
      }}
      sx={{ minWidth: "50%" }}
      groupKey="prices"
    >
      <h5 style={{ flexBasis: "100%", margin: 0, paddingBottom: 8 }}>Crossed Price</h5>
      <TextInput
        source="prices.crossedPriceExtended.priceId"
        label="Stripe ID"
        disabled
        sx={{ flex: "0 0 calc(25% - 16px)" }}
      />
      <NumberInput
        label="Price"
        source="prices.crossedPriceExtended.unitAmount"
        step={1}
        sx={{ flex: "0 0 calc(15% - 16px)" }}
      />
      <SelectInput
        source="prices.crossedPriceExtended.type"
        choices={[
          { id: "one_time", name: "One Time" },
          { id: "recurring", name: "Recurring" },
        ]}
        emptyText=""
        emptyValue=""
        validate={required()}
        defaultValue="one_time"
        label="Type"
        sx={{ flex: "0 0 calc(10% - 16px)" }}
      />
      <SelectInput
        source="prices.crossedPriceExtended.recurringInterval"
        choices={[
          { id: "month", name: "Month" },
          { id: "year", name: "Year" },
        ]}
        emptyText=""
        emptyValue=""
        defaultValue=""
        label="Recurring Interval"
        sx={{ flex: "0 0 calc(10% - 16px)" }}
      />
      <NumberInput
        label="Recurring Interval Count"
        source="prices.crossedPriceExtended.recurringIntervalCount"
        defaultValue={0}
        sx={{ flex: "0 0 calc(15% - 16px)" }}
      />
      <NumberInput
        label="Recurring Trial Period Days"
        source="prices.crossedPriceExtended.recurringTrialPeriodDays"
        defaultValue={0}
        sx={{ flex: "0 0 calc(15% - 16px)" }}
      />

      <h5 style={{ flexBasis: "100%", margin: 0, paddingBottom: 8 }}>Current Price</h5>
      <TextInput
        source="prices.currentPriceExtended.priceId"
        label="Stripe ID"
        disabled
        sx={{ flex: "0 0 calc(25% - 16px)" }}
      />
      <NumberInput
        label="Price"
        source="prices.currentPriceExtended.unitAmount"
        step={1}
        sx={{ flex: "0 0 calc(15% - 16px)" }}
      />

      <SelectInput
        source="prices.currentPriceExtended.type"
        choices={[
          { id: "one_time", name: "One Time" },
          { id: "recurring", name: "Recurring" },
        ]}
        emptyText=""
        emptyValue=""
        validate={required()}
        defaultValue="one_time"
        label="Type"
        sx={{ flex: "0 0 calc(10% - 16px)" }}
      />
      <SelectInput
        source="prices.currentPriceExtended.recurringInterval"
        choices={[
          { id: "month", name: "Month" },
          { id: "year", name: "Year" },
        ]}
        emptyText=""
        emptyValue=""
        defaultValue=""
        label="Recurring Interval"
        sx={{ flex: "0 0 calc(10% - 16px)" }}
      />
      <NumberInput
        label="Recurring Interval Count"
        source="prices.currentPriceExtended.recurringIntervalCount"
        defaultValue={0}
        sx={{ flex: "0 0 calc(15% - 16px)" }}
      />
      <NumberInput
        label="Recurring Trial Period Days"
        source="prices.currentPriceExtended.recurringTrialPeriodDays"
        defaultValue={0}
        sx={{ flex: "0 0 calc(15% - 16px)" }}
      />

      <TextInput
        source="prices.productSku"
        label="Product SKU"
        helperText={"(Optional) SKU to override default:  " + record.prodSku}
        sx={{ flexBasis: "95%" }}
      />
    </TranslatableInputs>
  );
};

const TranslationsInputForm: React.FC<PricesFormProps> = () => {
  const record = useRecordContext();
  const localeIds = useWatch({ name: "locales" });
  console.log("locale ids are", localeIds);
  const [translationLocaleKeys, setTranslationLocaleKeys] = useState<string[] | null>(null);

  useEffect(() => {
    if (localeIds) {
      let t_keys: string[] = ["en-GB"];
      dataProvider.getMany("locales", { id__in: localeIds }).then(({ data }) => {
        data.forEach((locale: Locale) => {
          locale?.supportedLanguages.forEach((lang) => t_keys.push(`${lang}-${locale.country}`));
        });
        setTranslationLocaleKeys([...new Set(t_keys)]);
      });
    }
  }, [localeIds]);

  if (!localeIds || localeIds.length === 0) {
    return (
      <Alert severity="warning" sx={{ width: "100%" }}>
        Please select locales
      </Alert>
    );
  }

  if (!translationLocaleKeys) {
    return <p>Loading...</p>;
  }
  return (
    <>
      <Alert severity="info" sx={{ width: "100%" }}>
        "en-GB" fields are required
      </Alert>
      <TranslatableInputs
        locales={translationLocaleKeys}
        defaultLocale={"en-GB"}
        sx={{ width: "100%" }}
        groupKey="translations"
        fullWidth
      >
        <TextInput
          source="translations.prodMarketingTitle"
          label="Marketing title"
          helperText="Title used in marketing (emails etc.)"
        />
        <TextInput source="translations.prodOrderTitle" label="Order title" helperText="Title used in order details" />
        <TextInput source="translations.description" helperText="Description of the product" label="Description" />

        <TextInput
          source="translations.prodImageTitle"
          label="Image title"
          helperText="Alt text for the product's image"
        />
        <TextInput
          source="translations.prodImageCornerNote"
          label="Image corner note"
          helperText="Text to be shown in the corner of the product image"
        />
        <TextInput
          source="translations.prodImageTooltipText"
          label="Image tooltip text"
          helperText="Text to show on the image as a tooltip"
        />
        <ImageArrayInputWithPreview source="translations.images" />
      </TranslatableInputs>
    </>
  );
};

export const ProductEdit = () => {
  const { control } = useForm();

  return (
    <Edit mutationMode="pessimistic" redirect={false}>
      <TabbedForm syncWithLocation={false}>
        <TabbedForm.Tab label={<SummaryTabHeader />}>
          <TextInput source="name" validate={required()} sx={{ minWidth: "400px" }} />
          <ReferenceArrayInput
            label="Tags"
            reference="productTags"
            source="tags"
            perPage={100}
            filter={{ active: true }}
          >
            <AutocompleteArrayInput
              validate={required()}
              label="Tags"
              optionText={(record) => `${record.name}`}
              filterToQuery={filterToQueryTags}
            />
          </ReferenceArrayInput>
          <TextInput source="prodSku" sx={{ minWidth: "400px" }} />
          <TextInput source="prodBarcode" sx={{ minWidth: "400px" }} />
          <TextInput source="image" sx={{ minWidth: "400px" }} />
          <ImageField sx={{ "& img": { maxWidth: 400, maxHeight: 100, objectFit: "contain" } }} source="image" />
          <ReferenceArrayInput source="locales" reference="locales">
            <AutocompleteArrayInput validate={required()} label="Locales" optionText={(record) => `${record.name}`} />
          </ReferenceArrayInput>
        </TabbedForm.Tab>
        <TabbedForm.Tab label={<TranslationsTabHeader />}>
          <TranslationsInputForm control={control} />
        </TabbedForm.Tab>
        <TabbedForm.Tab label={<PricesTabHeader />}>
          <PricesInputForm control={control} />
        </TabbedForm.Tab>
      </TabbedForm>
    </Edit>
  );
};

export const ProductCreate = () => {
  return (
    <Create>
      <TabbedForm syncWithLocation={false}>
        <TabbedForm.Tab label={<SummaryTabHeader />}>
          <TextInput source="name" validate={required()} sx={{ minWidth: "400px" }} />
          <ReferenceArrayInput label="Tags" reference="productTags" source="tags" perPage={100}>
            <AutocompleteArrayInput
              validate={required()}
              label="Tags"
              optionText={(record) => `${record.name}`}
              filterToQuery={filterToQueryTags}
            />
          </ReferenceArrayInput>
          <TextInput source="prodSku" sx={{ minWidth: "400px" }} />
          <TextInput source="prodBarcode" sx={{ minWidth: "400px" }} />
          <TextInput source="image" sx={{ minWidth: "400px" }} />
          <ImageField sx={{ "& img": { maxWidth: 400, maxHeight: 100, objectFit: "contain" } }} source="image" />
          <ReferenceArrayInput source="locales" reference="locales">
            <AutocompleteArrayInput validate={required()} label="Locales" optionText={(record) => `${record.name}`} />
          </ReferenceArrayInput>
        </TabbedForm.Tab>
      </TabbedForm>
    </Create>
  );
};
